import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

class SiteItem extends React.Component {
  formatDate(date) {
    const dateNum = date.split("T")[0];
    const time = date.split("T")[1].split("+")[0];
    return dateNum + " " + time;
  }

  render() {
    let item = this.props.item;
    return (
      <div>
        <div>
          <span className="item-date">{this.formatDate(item.publishDate)}</span>
          <span><a href={item.link}>{item.title}</a></span>
        </div>
      </div>
    );
  }
}

class SiteSection extends React.Component {
  constructor() {
    super();
    this.state = {
      showSiteSection: true
    }
  }
  renderSiteItem(item) {
    return <SiteItem key={item.title} item={item}/>;
  }

  extractHostnameFromUrl(url) {
    var hostname;
    hostname = url; // TODO: remove this
    // //find & remove protocol (http, ftp, etc.) and get hostname
    // if (url.indexOf("//") > -1) {
    //   hostname = url.split('/')[2];
    // } else {
    //   hostname = url.split('/')[0];
    // }

    // //find & remove port number / params
    // hostname = hostname.split(':')[0];
    // hostname = hostname.split('?')[0];
    return hostname;
  }

  hideSiteSection() {
    this.setState({
      showSiteSection: false
    })
  }

  render() {
    const items = this.props.items;
    let styles = {display: 'inherit'}
    if (!this.state.showSiteSection) {
      styles = {display: 'none'}
    }
    if (items !== null) {
      return (
        <div className="rssfeed-site-section" style={styles}>
          <div className="rssfeed-site-section-title">
            <div><a href={this.props.url}>{this.extractHostnameFromUrl(this.props.url)}</a></div>
            <div className="rssfeed-site-section-title-button" onClick={() => this.props.removeUrlHandler(this.props.url, this.hideSiteSection.bind(this))}><div>Remove</div></div>
          </div>
          <div>{items.map(item => this.renderSiteItem(item))}</div>
        </div>
      );
    }
    return null;
  }
}

class RSSFeed extends React.Component {
  constructor() {
    super();
    this.state = {
      urls: [
        'https://news.ycombinator.com/rss',
        'http://www.reddit.com/r/programming/.rss',
        'http://www.reddit.com/r/rails/.rss',
        'http://www.reddit.com/r/askreddit/.rss',
        'https://www.npr.org/rss/podcast.php?id=510289',
        'https://javascriptweekly.com/rss',
        'https://www.wired.com/feed/category/science/latest/rss',
        'https://www.wired.com/feed/category/business/latest/rss'
      ],
      rssUrlToFeedHash: {},
      value: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeUrl = this.removeUrl.bind(this);
  }

  componentDidMount() {
    this.updateStateUrlsFromLocalStorageUrls();
    this.getRss(this.state.urls);
    // const getNewsfeedTimed = setInterval(function() {
    //   this.getRss(this.state.urls);
    // }, 10000);
  }

  updateStateUrlsFromLocalStorageUrls() {
    // Get urls from local storage and updates react state
    let updatedUrls = this.state.urls;
    let localStorageUrls = localStorage.urls;

    // Add localStorage URL into updatedUrls (to update state urls)
    if (localStorageUrls !== undefined) {
      JSON.parse(localStorageUrls).map(url => updatedUrls.push(url));
    }
    updatedUrls = new Set(updatedUrls);
    updatedUrls = Array.from(updatedUrls);
    localStorage.setItem('urls', JSON.stringify(updatedUrls));

    this.setState({
      urls: updatedUrls
    })
  }

  removeUrl(url) {
    if (this.state.urls.includes(url)) {
      let indexOfUrlToBeRemoved = this.state.urls.indexof(url);
      let newUrls = this.state.urls.splice(indexOfUrlToBeRemoved, 1);
      this.setState({
        urls: newUrls
      })
    }
  }

  getRss(list) {
    return list.map(url => this.fetchSiteRSS(url));
  }

  fetchSiteRSS(url) {
    fetch("https://feed.jquery-plugins.net/load?url=" + url, {mode: "cors"})
      .then(res => res.json())
      .then((result => {
        let rssUrlToFeedHash = this.state.rssUrlToFeedHash;
        if (this.state.rssUrlToFeedHash !== undefined) {
          rssUrlToFeedHash[url] = result.data;
        }
          
        this.setState({
          rssUrlToFeedHash: rssUrlToFeedHash
        })
      }))
    return;
  }

  renderRSSForSite(items) {
    if (items !== undefined) {
      return Object.keys(items).map(url => <SiteSection key={url} url={url} items={items[url]} removeUrlHandler={this.removeUrl}/>);
    }
  }

  removeUrl(url, callback) {
    if (this.state !== undefined && this.state.urls !== undefined && this.state.urls.includes(url)) {
      let indexOfUrlToBeRemoved = this.state.urls.indexOf(url);
      let newUrls = this.state.urls
      newUrls.splice(indexOfUrlToBeRemoved, 1);
      this.setState({
        urls: newUrls
      })
      localStorage.setItem('urls', JSON.stringify(newUrls));
      alert(url + " removed from rss feed");
    }
    callback();
  }

  addUrl(url) {
    let newUrls = this.state.urls;
    if (url === undefined || url === "") {
      return newUrls;
    }
    alert('Added RSS feed to bottom: ' + this.state.value);
    newUrls.push(this.state.value);

    // This is dumb way to check for uniqueness
    newUrls = new Set(newUrls);
    newUrls = Array.from(newUrls);
    localStorage.setItem('urls', JSON.stringify(newUrls));
    return newUrls;
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    this.setState({
      urls: this.addUrl(this.state.value),
      value: ''
    })
    this.getRss(this.state.urls);
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <div className="page-header">
          <div>
            <a href="/" style={{'color':'white','text-decoration':'none'}}>RSS Feed</a>
          </div>
          <div>
            <form onSubmit={this.handleSubmit}>
              <label>
                <input type="text" value={this.state.value} onChange={this.handleChange} placeholder="Add your RSS feed url"/>
              </label>
              <span style={{'paddingLeft':'8px'}}>
              <input type="submit" value="Submit" />
              </span>
            </form>
          </div>
        </div>
        <div className="page-content">{this.renderRSSForSite(this.state.rssUrlToFeedHash)}</div>
        <div className="page-footer">Last Refreshed: {new Date().toLocaleString()}</div>
      </div>
    );
  }
}

ReactDOM.render(
  <RSSFeed />,
  document.getElementById('root')
);
